.bu {
    background: var(--main-button-color);
    color: #fff;
    font-weight: 600;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    min-width: 100px;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
}
.bu.light {
    border: 1px solid var(--main-button-color);
    background: #fff;
    color: var(--main-button-color);
}

.bu:hover {
    box-shadow: 0px 0 200px rgba(0, 0, 0, 0.25) inset;
    color: #fff;
}
.bu.light:hover {
    box-shadow: 0px 0 200px rgba(0, 0, 0, 0.05) inset;
}
.form input,
.form select {
    width: 100%;
    background: #fff;
    padding: 5px;
    border: 1px solid #999;
    border-radius: 3px;
}
.form .checkbox {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
}
.form .checkbox input {
    width: auto;
    margin: 0 5px 0 0;
}
.info {
    color: #ca0d0d;
    margin-bottom: 10px;
    font-size: 0.85em;
}
.draggable-list input {
    background: none;
    border: none;
    width: 100%;
    border-radius: 5px;
}
.small-bu {
    margin: 0 auto;
    font-size: 13px;
    display: inline-flex;
}
[data-type="input"] {
    background: #fff;
    padding: 5px 15px 5px 5px;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: pointer;
    font-size: 0.85em;
    position: relative;
}
[data-type="input"]::after {
    position: absolute;
    right: 10px;
    width: 5px;
    height: 5px;
    border: 2px solid #000;
    border-width: 0 2px 2px 0;
    content: " ";
    top: 50%;
    margin-top: -5px;
    transform: rotate(45deg);
}
.form-table {
    display: table;
    table-layout: fixed;
    width: 100%;
}
.form-table > * {
    display: table-row;
}
.form-table > * > * {
    display: table-cell;
    padding-bottom: 20px;
    padding-left: 10px;
}
.form-table > * > *:first-child {
    padding-left: 0;
}

.tab-h {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    background: #fff;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
    flex-wrap: wrap;
    position: sticky;
    top: 0;
    height: 50px;
    align-items: center;
}
.tab-h li {
    font-size: 0.85em;
    line-height: 2.25rem;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    padding: 0 20px;
    cursor: pointer;
    text-decoration: none;
    color: #666;
    justify-content: center;
    position: relative;
    height: 50px;
    align-items: center;
    border-right: 1px solid #eee;
    white-space: nowrap;
}

.tab-h li.active {
    color: #000;
    position: relative;
}
.tab-h li.selected {
    color: #33b679;
}
.tab-h li.selected::before {
    content: "✔";
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: #33b679;
    position: absolute;
    bottom: 2px;
    z-index: 1;
}

.tab-h li.active::after {
    content: " ";
    position: absolute;
    bottom: 0;
    background: var(--main-button-color);
    width: 100%;
    height: 3px;
}
.tab-h li.selected::after {
    background: #33b679;
}
.tab-c {
    display: block;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    max-width: 1200px;
}
.tab-c > li {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
}
.tab-c > li.active {
    display: block;
}

.table {
    display: table;
    border-collapse: collapse;
    font-size: 0.9em;
    width: 100%;
    table-layout: fixed;
}
.table > * {
    display: table-row;
}
.table > * > * {
    display: table-cell;
    border: 1px solid #dedede;
    padding: 5px 15px;
}
.table-head > * {
    background: #f5f5f5;
    font-weight: 600;
}
.opt-menu {
    display: inline-block;
    color: #000;
    text-decoration: none;
}
.opt-menu-title {
    display: flex;
    font-size: 0.9em;
    align-items: center;
    cursor: pointer;
    color:#6e6d7a;
}
.opt-menu-title:hover {    
    color:#000;
}
.opt-menu-title strong {
    font-size: 20px;
    width: 20px;
    height: 20px;
    text-align: center;
    color: #000;
}
.opt-menu-links {
    position: relative;
    display: none;
}
.opt-menu-links.on {
    display: block;
}
.opt-menu-links ul {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    border-radius: 3px;
    z-index: 1;
    flex-direction: column;
    z-index: 1001;
}
.opt-menu-links ul,
.opt-menu-links li {
    margin: 0;
    padding: 0;
    list-style: none;
}
.opt-menu-links li {
    display: block;
    font-weight: normal;
    font-size: 0.85em;
    width: 100%;
    padding: 10px 15px 10px 15px;
    text-align: left;
    display: block;
    cursor: pointer;
    white-space: nowrap;
}
.opt-menu-links li:hover {
    background: rgba(0, 0, 0, 0.05);
}
.opt-menu-links li a{
    text-decoration: none;
    color: #000;
}
.small-bu {
    display: inline-flex;
    border: 1px solid var(--main-button-color);
    background: #fff;
    color: var(--main-button-color);
    text-align: center;
    font-weight: 600;
    border-radius: 3px;
    height: 32px;
    align-items: center;
    padding: 0 10px;
    min-width: 100px;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
}
.small-bu:hover {
    background: var(--main-button-color);
    color: #fff;
}
.select-p {
}
.select-p input {
    visibility: hidden;
    position: absolute;
}
.select-p input + i {
    width: 28px;
    height: 28px;
    border: 2px solid var(--main-button-color);
    display: block;
    left: 0%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2);
}
.select-p input:checked + i::before {
    content: "";
    width: 12px;
    height: 12px;
    border-radius: 50%;
    left: 50%;
    top: 50%;
    background: var(--main-button-color);

    transform: translate(-50%, -50%);
    position: absolute;
}
.select-check input + i {
    border-radius: 5px;
}
.select-check input:checked + i {
    border-color: #33b679;
    background: #64c799;
}
.select-check input:checked + i::before {
    color: var(--main-button-color);
    background: none;
    border: 3px solid #fff;
    border-width: 0 3px 3px 0;
    height: 10px;
    width: 4px;
    border-radius: 0;
    transform: translate(-50%, -50%) rotate(45deg);
}

.t-tabs {
    display: flex;
    border-bottom: 1px solid#ddd;
    background: #fff;
}
.t-tabs a {
    line-height: 2.25rem;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    padding: 0 20px;
    cursor: pointer;
    text-decoration: none;
    color: #666;
    justify-content: center;
    position: relative;
    height: 50px;
    align-items: center;
    border-right: 1px solid #ddd;
    white-space: nowrap;
}
.t-tabs a.active {
    color: #000;
    position: relative;
    background: #fafafa;
}
.t-tabs a.active::after {
    content: " ";
    position: absolute;
    bottom: -1px;
    background: var(--main-button-color);
    width: 100%;
    height: 3px;
}
.t-item {
    display: none;
}

.t-item.active {
    display: block;
}
dl.t-item.active {
    display: flex;
}
@media screen and (max-width: 1024px) {
    .tab-h {
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
    }
    .tab-h li.selected::before {
        width: 14px;
        height: 14px;
    }
}
.form-field {
    padding-bottom: 20px;
}
.form-field label {
    font-size: 0.89em;
    padding-bottom: 5px;
    display: block;
    font-weight: 600;
}
.form-add-filed {
    margin-top: -15px;
    padding-bottom: 15px;
}
.form-add-filed a {
    font-size: 0.8em;
    text-decoration: underline;
    color: #666;
    cursor: pointer;
}
.form-add-filed a:hover {
    text-decoration: none;
}
.form-option p {
    font-size: 0.85em;
    padding: 0 0 15px;
    margin: 0;
}
.hr {
    padding-top: 10px;
    margin-bottom: 5px;
    margin-top: 30px;
    border-top: 1px solid #eee;
}
[hide] {
    display: none;
}
.form-option {
    padding: 10px;
    background: #f9f9f9;
    margin-bottom: 20px;
    border: 1px solid #ddd;
}
.form .check-box-label {
    display: inline-flex;
    font-weight: normal;
    align-items: center;
    font-size: 0.85em;
    white-space: nowrap;
    font-size: 0.8em;
    padding-bottom: 5px;
    display: block;
    font-weight: 600;
}
.form .check-box-label input {
    width: auto;
    margin: 0 5px 0 0;
}
.two-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.two-field > * {
    width: 100%;
}
.two-field > *:first-child {
    padding-right: 5px;
}
.two-field > *:last-child {
    padding-left: 5px;
}
[data-target-option] > * {
    display: none;
}
[data-target-option] > *.on {
    display: block;
}
[data-showhide-list] {
    padding-top: 10px;
    padding-bottom: 10px;
    display: none;
}
.toggle-field {
    display: none;
}
fieldset {
    margin: 20px 10px;
    border-radius: 5px;
    border: none;
    padding: 20px;
    border: 1px solid #ccc;
}
fieldset input[type="text"],
fieldset input[type="number"] {
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 2px;
    width: 100%;
    font-size: 1rem;
}
fieldset legend {
    font-weight: 600;
    padding: 0 10px;
}

.radio label {
    display: flex;
    align-items: center;
    margin: 0 10px 10px 0;

    font-size: 0.8em;
}

.radio input {
    margin: 0 5px 0 0;
    padding: 0;
}
.checkbox {
    display: flex;
    align-items: center;
    margin: 0 10px 10px 0;

    font-size: 0.8em;
}
.checkbox input {
    margin: 0 5px 0 0;
    padding: 0;
}
fieldset h4 {
    font-size: 0.8em;
    font-weight: 600;
    padding-bottom: 10px;
}
fieldset textarea {
    border: 1px solid #ccc;
    width: 100%;
    height: 6em;
    padding: 10px;
    font-family: Arial, Helvetica, sans-serif;
}
.switch {
    position: relative;
    display: inline-block;
    width: 46px;
    height: 20px;
    flex-shrink: 0;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 10px;
    width: 10px;
    left: 4px;
    bottom: 4px;
    background-color: #c0c6d4;
    transition: 0.4s;
}

input:checked + .slider {
    background-color: var(--main-button-color);
    border-color: var(--main-button-color);
}

input:focus + .slider {
    box-shadow: 0 0 1pxvar (--main-button-color);
}

input:checked + .slider:before {
    transform: translateX(26px);
    background: #fff;
}

/* Rounded sliders */
.slider.round {
    border-radius: 34px;
    border: 1px solid #dedede;
    background: #fff;
}

.slider.round:before {
    border-radius: 50%;
}
.date-type {
    margin: 0 30px 0 auto;
}

.daterange {
    display: flex;
    padding-top: 10px;
}
.daterange > * {
    width: 100%;
}
.daterange > *:first-child {
    padding-right: 5px;
}
.daterange > *:last-child {
    padding-left: 5px;
}
.daterange label {
    font-size: 0.8em;
    display: block;
    padding-bottom: 5px;
    font-weight: 600;
}
.daterange input {
    width: 100%;
}
.flex {
    display: flex;
    align-items: center;
}
.pl10 {
    padding-left: 10px;
}
