:root {
    --main-button-color: #1a73e8;
    --border-color: #f3f4f4;
}

* {
    box-sizing: border-box;
}
h4 {
    font-weight: 500;
    font-size: 0.95em;
}
html,
body,
h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    padding: 0;
}
body {
    background: #fff;
    font-family: Arial, Helvetica, sans-serif;
}
input {
    font-family: Arial, Helvetica, sans-serif;
}
/* [start] Header styles */
header {
    width: 100%;
    background: #fff;
    z-index: 9996;
    color: #6e6d7a;
    position: fixed;
    top: 0;
    left: 0;
    height: 60px;
    border-bottom: 1px solid #ddd;
    padding: 0 30px;
    display: flex;

    align-items: center;
}
nav {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
}
nav > ul {
    display: flex;
    flex: 1;
    list-style: none;
    margin: 0 30px 0 30px;
    padding: 0;
    height: 80px;
}
nav > ul > li {
    display: flex;
    align-items: center;
    padding: 0 15px;
}
nav > ul > li > a {
    color: #6e6d7a;
    font-weight: 400;
    cursor: pointer;
    position: relative;
    padding-right: 10px;
    font-size: 0.9em;
    text-decoration: none;
}

nav > ul > li > a::after {
    content: "";
    box-sizing: border-box;
    height: 6px;
    width: 6px;
    border-style: solid;
    border-color: #222;
    border-width: 0px 1px 1px 0px;
    position: absolute;
    right: 0;
    transform: rotate(45deg);
    top: 50%;
    margin-top: -4px;
}

.nav-dropdown {
    display: none;
    background-color: #fff;
    position: absolute;
    z-index: 1000;
    left: 0;
    top: 80px;
    width: 100vw;
    box-shadow: 0px 20px 35px rgba(0, 0, 0, 0.1);
    padding: 30px;
    min-height: 250px;
    align-items: center;
    justify-content: center;
}
nav li:hover .nav-dropdown {
    display: flex;
}
nav li:hover a {
    color: #222;
}
/* [end] Header styles */

main {
    padding-top: 80px;
}

/* [start] planning page */
.date-header {
    height: 80px;
    align-items: center;
    display: flex;
    padding: 0 30px;
    justify-content: center;
    border-bottom: 1px solid var(--border-color);
}
.date {
    font-size: 1.22em;
    font-weight: 400;
}
.date-header a.today {
    background-color: transparent;
    border: 1px solid #dadce0;
    border-radius: 4px;
    height: 36px;
    display: flex;
    align-items: center;
    font-size: 0.85em;
    font-weight: 500;
    padding: 0 20px;
    cursor: pointer;
    color: #3c4043;
}
.date-header a.today:hover {
    background: #f6f6f6;
}
.date-nav {
    display: flex;
    padding: 0 10px;
}
.date-nav a {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.date-nav a::before {
    border: 2px solid #5f6368;
    border-width: 2px 2px 0 0;
    content: " ";
    height: 8px;
    width: 8px;
    display: block;
    transform: rotate(-135deg);
}
.date-nav a:last-child::before {
    transform: rotate(45deg);
}
.date-nav a:hover {
    background: #f6f6f6;
}
.planning-stages {
    min-height: calc(100vh - 160px);
    display: flex;
}
.planning-stages > div {
    width: 100%;
}
.boat-transport {
    background: #f9fbff;
}
.section-heading {
    text-align: center;
}
.section-heading h2 {
    font-size: 1.1em;
    font-weight: 600;
}
.section-details {
    width: 100%;
    display: table;
    justify-content: space-between;
    padding: 5px 20px 0;
    font-size: 0.85em;

    table-layout: fixed;
}
.section-details > * {
    display: table-cell;
    text-align: center;
}
.section-details > *:first-child {
    text-align: left;
}
.section-details > *:last-child {
    text-align: right;
}
.section-details h3 {
    font-size: 1em;
    font-weight: 400;
}
.section-details h3 span {
    background: #33b679;
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 10px;
}
.section-details a {
    text-decoration: underline;
    cursor: pointer;
}
.section-details a:hover {
    text-decoration: none;
}
.section-details .warning {
    color: #c30f00;
    text-decoration: underline;
    cursor: pointer;
}
.section-details .warning.success {
    color: seagreen;
}
.section-details .warning.success svg {
    vertical-align: middle;
    fill: seagreen;
}
.section-details .warning:hover {
    text-decoration: none;
}
.section-heading-container {
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.landtransport {
    min-height: calc(100vh - 240px);
    display: flex;
}
.landtransport h4 {
    padding-bottom: 5px;
    padding-left: 5px;
    font-weight: 500;
}
.landtransport > div {
    width: 100%;
    padding: 20px;
}
.landtransport > div:first-child {
    border-right: 1px solid var(--border-color);
}
.landtransport > div:last-child {
    padding: 20px 30px 20px 15px;
}
.draggable-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}
.draggable-list li {
    display: table;
    table-layout: fixed;
    font-size: 0.85em;

    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    padding: 5px;
    margin: 5px 0;
    width: 100%;
    cursor: pointer;
}
.draggable-list li:last-child,
.container-list .draggable-list li:last-child {
    margin-bottom: 0;
}
.draggable-list li > div {
    display: table-cell;
    white-space: nowrap;
}
.draggable-list li > div.time {
    width: 60px;
    padding-right: 5px;
}
.draggable-list li span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
.draggable-list li .action {
    text-align: right;
    width: 40px;
}

.draggable-list li .status-text {
    text-align: right;
    width: 50px;
}

.draggable-list li .action i,
.draggable-list li .status-text i {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-weight: 500;
    line-height: 1;

    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.08);
    font-style: normal;
}
.draggable-list li .status-text {
    font-size: 0.85em;
}
.gcount {
    width: 25px;
    height: 25px;
    display: flex;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
}
.draggable-list li .action a {
    font-weight: 800;
    width: 10px;
    display: inline-block;
}
ul + h4 {
    padding-top: 30px;
}
.van {
    border: 1px dashed #666;
    padding: 10px;
    margin: 5px 0 10px;
    border-radius: 5px;
}
.van.startedfilling {
    border: 1px solid var(--main-button-color);
    background-color: #f9fbff;
}
.van.limit {
    border-color: red;
    background: #fff5f5;
}
.van.startedfilling h5 i {
    background-color: #f18d33;
    color: #fff;
}
.van.limit h5 i {
    background-color: red;
    color: #fff;
}
.van h5 {
    margin: 0;
    padding: 0;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
}
.van h5 i {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-weight: 500;
    line-height: 1;

    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 10px;
    font-size: 12px;
    font-style: normal;
}
.vans + .vans {
    padding-top: 20px;
}
.completed .van {
    border: none;
    background: #33b679;
    color: #fff;
}
.completed .van h5 i {
    background: rgba(0, 0, 0, 0.1);
}

.van + .bu {
    background: none;
    border: 1px solid var(--main-button-color);
    color: var(--main-button-color);
    justify-content: left;
}
.van + .bu:hover {
    background: var(--main-button-color);
    color: #fff;
}
.draggable-list li .action.varification-failed {
    width: 80px;
}
.draggable-list li .action.varification-failed strong {
    vertical-align: middle;
}
.draggable-list li .action.varification-failed strong svg {
    fill: red;
}
/* [end] planning page */
/* [start] booking page */
.booking-header {
    height: 80px;
    display: flex;
    border-bottom: 1px solid var(--border-color);
    padding: 0 0 0 20px;
}
.booking-header > div {
    width: 100%;
    display: flex;
    align-items: center;
}
.mandatory-form {
    display: flex;
    width: 100%;
    padding-right: 10px;
}
.mandatory-form > * {
    width: 100%;
    padding-right: 10px;
}
.mandatory-guest-details h2 {
    font-size: 0.95em;
    padding-bottom: 5px;
    font-weight: 600;
}
.mandatory-guest-details .form {
    width: 100%;
}
.mandatory-guest-header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    padding-right: 20px;
}
.mandatory-guest-header a {
    text-decoration: underline;
    font-size: 0.85em;
    cursor: pointer;
}
#history {
    display: none;
}
.mandatory-guest-header a:hover {
    text-decoration: none;
}
.guest-number {
    padding: 0 20px;
    background: #f9fbff;
    border-left: 1px solid var(--border-color);
}
.guest-number label {
    display: block;
    padding-bottom: 5px;
    font-size: 0.8em;
}
.guest-number > * {
    padding-right: 10px;
    width: 100%;
}
.booking-section {
    display: flex;
    min-height: calc(100vh - 160px);
}

.booking-section > * {
    width: 50%;
}
.room-selection {
    border-left: 1px solid var(--border-color);
}

.package-control {
    display: flex;
    min-height: 100%;
}
.package-control > * {
    width: 50%;
}
.package-list {
    background: #f9fbff;
    padding: 20px;
}
.package-list h3 {
    font-weight: 600;
    font-size: 0.95em;
    padding-bottom: 10px;
}
.package-list-header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}
#range-label {
    font-weight: 700;
    font-size: 0.8em;
    color: var(--main-button-color);
}

.bubble-list {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    justify-content: space-between;
}
.bubble-list.single-list {
    flex-wrap: nowrap;
}
.bubble-list li {
    margin: 5px 0;
    width: calc(50% - 5px);
    background: #fff;
    border: 1px solid #abc4ff;
    border-radius: 10px;
    display: flex;
    min-height: 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0 10px;
    font-size: 0.9em;
    font-weight: 500;
    cursor: pointer;
    color: #000;
    line-height: 1em;
    flex-direction: column;
}
.bubble-list li em {
    font-size: 0.75em;
    font-weight: normal;
    line-height: 1em;
    color: #666;
}
.bubble-list.single-list > li {
    margin-right: 5px;
}
.bubble-list.single-list > li:last-child {
    margin-right: 0;
}
.bubble-list li.active {
    border-color: var(--main-button-color);
    color: var(--main-button-color);
    background-color: #e5edff;
    box-shadow: 1px 5px 7px rgba(0, 0, 0, 0.2);
}
.bubble-list li.active em {
    color: var(--main-button-color);
}
.package-list-container {
    padding-top: 20px;
}
.bubble-target {
    display: none;
}
.bubble-target.active {
    display: block;
}
.bubble-has-value > .default-text {
    display: none;
}
.addpackage {
    padding: 20px 0;
    text-align: right;
    font-size: 0.85em;
}
.addpackage a {
    text-decoration: underline;
    cursor: pointer;
}
.addpackage a:hover {
    text-decoration: none;
}
.package-option-list {
    padding: 20px;
}
.default-text {
    padding: 20px 15%;
    font-size: 0.8em;
    color: rgba(0, 0, 0, 0.5);
    text-align: center;
    margin: 10px 0;
    border-bottom: 1px solid var(--border-color);
    border-top: 1px solid var(--border-color);
}
.package-option-list h4 {
    padding-bottom: 10px;
}

.listofdetails {
    font-size: 0.75em;
    padding-bottom: 20px;
    color: #666;
}
.listofdetails dl {
    padding: 0 0 0 34px;
    position: relative;
}
.listofdetails dl dt {
    position: absolute;
    left: 0;
    top: 0;
}
.listofdetails dl dt svg {
    width: 16px;
    height: 16px;
}
.listofdetails dl dd {
    padding: 0;
    margin: 0;
}
#dc-options-container {
    padding: 10px 0 10px;
}
#dc-options-container h5 {
    font-size: 0.985em;
    font-weight: 400;
    padding-bottom: 5px;
}
.diving-duration {
    font-weight: 400;
    font-size: 0.75em;
    text-align: right;
}
.additional-days-container {
    padding: 10px 0 20px;
    display: none;
}
.additional-days-container h4 {
    display: flex;
    justify-content: space-between;
}
.child-activated .additional-days-container {
    display: block;
}
#needmoredays span {
    color: #c30f00;
    font-weight: normal;
    font-size: 0.9em;
}
.roomheader {
    height: 80px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
}
.check-in-date {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.check-in-date label {
    display: block;
    padding-bottom: 5px;
    font-size: 0.8em;
}
.check-in-date span {
    padding-right: 10px;
}
.total-night-stay {
    padding-top: calc(1em + 5px);
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.total-night-stay span {
    font-size: 0.85em;
}
.typeofseason {
}
.typeofseason a {
    font-size: 0.8em;
    display: flex;
    align-items: center;
    display: none;
    color: #666;
}
.diiving-instractor-status span {
    display: block;
    padding: 0 0 10px;
    font-weight: 500;
    font-size: 0.85em;
    color: seagreen;
}
.typeofseason.show a {
    display: flex;
}
.error-date {
    width: 100%;
}
.error-date span {
    display: block;
    color: #c30f00;
    font-size: 0.85em;
}
.packageerror span {
    display: block;
    color: #c30f00;
    font-size: 0.85em;
    padding-bottom: 20px;
}
.guestandroom {
    display: flex;
    min-height: calc(100vh - 240px);
}
.guestandroom > * {
    width: 50%;
}
.guestandroom > *:last-child {
    border-left: 1px solid var(--border-color);
}
.g-guests-lists {
    padding: 10px;
}
.g-guests-lists li,
.container-list .draggable-list li {
    border-radius: 5px;
    border: 1px solid #dedede;
    margin: 0 0 10px;
}
.g-guests-lists li > div,
.container-list li > div {
    vertical-align: middle;
}
.g-guests-lists li > div input,
.container-list li > div input {
    height: 25px;
    border-radius: 5px;
}
.g-guests-lists li > div.status-text span {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: visible;
}
.g-guests-lists li > div.status-text a {
    font-size: 20px;
    font-weight: bold;
    position: relative;
}
.g-guests-lists li > div.status-text a:hover .optionmenu {
    display: block;
}
.g-guests-lists li > div.status-text i {
    font-size: 0.95em;
}
.optionmenu {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    position: absolute;
    right: 0;
    top: 0;
    background: #fff;
    border-radius: 3px;
    z-index: 1;
    flex-direction: column;
    display: none;
}
.g-guests-lists li > div.status-text .optionmenu a {
    font-weight: normal;
    font-size: 14px;
    width: 100%;
    padding: 15px 20px 15px 10px;
    text-align: left;
    display: block;
}
.g-guests-lists li > div.status-text a:hover {
    background: rgba(0, 0, 0, 0.05);
}

.resortListing {
    padding: 0 10px 10px;
}
#populateGuests {
    min-height: 100%;
}
#room-container {
    display: none;
}
.addrooms-link {
    padding: 10px 20px;
}
.room-seclection-popup {
    position: fixed;
    top: 0;
    width: 50%;
    background: #fff;
    overflow: auto;
    left: 0;
    height: 100vh;
    z-index: 10000;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.4);
    display: none;
}
.room-popup-heading {
    position: relative;
}
#closrrompopup {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    width: 50px;
    height: 50px;
    background: var(--main-button-color);
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
}
.roompopup-showing .room-seclection-popup {
    display: block;
}
#addroomspopup {
    font-size: 0.8em;
    text-decoration: underline;
    cursor: pointer;
    text-align: right;
}
.roompopup-showing #addroomspopup {
    display: none;
}
.room-container-heading {
    display: flex;
    justify-content: space-between;
    padding: 0 30px 0 20px;
    height: 50px;
    align-items: center;
}
.roomwarning {
    padding: 0 30px 10px 20px;
    font-size: 0.85em;
    color: #666;
}
.roomwarning strong {
    color: #000;
    font-weight: 500;
}
#populateRoom {
    display: none;
}
.container-list {
    list-style: none;
    margin: 0;
    padding: 0;
}
.container-list > li {
    margin-bottom: 10px;
    border: 1px dashed #666;
    border-radius: 5px;
    min-height: 60px;
}
.container-list > li.activeRoom {
    border: 1px solid var(--main-button-color);
    background-color: #f9fbff;
}
.container-list > li.activeRoom h4 span {
    font-weight: 600;
    color: var(--main-button-color);
}
.container-list li .draggable-list {
    padding: 5px 10px;
}
.container-list > li > h4 {
    padding: 10px 10px 0;
    font-size: 0.85em;
    font-weight: normal;
    display: flex;
    justify-content: space-between;
}
.container-list > li > h4 strong {
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    font-weight: 500;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 10px;
    font-size: 12px;
    font-style: normal;
    background-color: #f18d33;
    color: #fff;
    display: none;
}
.container-list > li.activeRoom > h4 strong {
    display: inline-block;
}

.room-footer {
    display: flex;
    justify-content: flex-end;
}
.room-footer span {
    font-size: 0.8em;
    height: 22px;
    align-items: center;
    display: flex;
    padding-right: 10px;
    color: #777;
}
.room-footer span i {
    font-style: normal;
    padding-right: 4px;
}
.room-footer a {
    background: var(--main-button-color);
    color: #fff;
    cursor: pointer;
    height: 22px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;
}
.container-list > li.activeRoom.maximum-limit {
    border: 1px solid red;
    background-color: #fff5f5;
}
.container-list > li.activeRoom.maximum-limit > h4 strong {
    background: red;
}
.container-list > li.activeRoom.maximum-limit h4 span {
    color: red;
}
#populateGuests.priceshowing {
    min-height: 100px;
    border: 1px dashed #999;
    border-radius: 5px;
}
.totalprice {
    display: none;
    padding: 10px 0;
}
#populateGuests.priceshowing + .totalprice {
    display: block;
}
