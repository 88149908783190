.calendar-view {
    height: auto;
    overflow: auto;
}

.calendar-dates-heading {
    position: sticky;
    display: flex;
    top: 0;
    padding: 0 0;
    z-index: 10;
    width: 100%;
    border-bottom: 1px solid #eee;
    background: #fff;
}
.calendar-dates-heading .date-header {
    justify-content: start;
    padding: 0;
    height: 50px;
    background: #fff;
}
.c-room-name {
    width: 80px;
    flex-shrink: 0;
}
.calendar-month {
    width: calc(100% - 80px);
    flex-shrink: 0;
    font-size: 0.85em;
    border-left: 1px solid #ddd;
    position: relative;
}
.c-m-dates.c-wm-dates {
    display: flex;
}
.c-m-dates.c-wm-dates > div {
    width: 3.225806451612903%;
    flex-shrink: 0;
    text-align: center;
    line-height: 1.5em;
    border-right: 1px solid #ddd;
    height: 60px;
}

.calendar-bookings {
    display: flex;
}
.calendar-rooms {
    width: 80px;
    flex-shrink: 0;
    position: sticky;
    left: 0;
    background: rgba(255, 255, 255, 0.86);
    z-index: 2;
}
.c-m-dates.c-wm-dates > div > span {
    font-size: 11px;
    display: block;
    color: #666;
    text-transform: uppercase;
}
.c-m-dates.c-wm-dates > div > div {
    font-size: 11px;
    color: green;
}
.c-m-name {
    padding-bottom: 10px;
}
.calendar-booking {
    display: flex;
    position: relative;
}
.calendar-booking .c-m-dates.c-wm-dates > div {
    height: 40px;
}
.c-room-type {
    width: 100%;
    height: 59px;
}
.c-m-dates.c-wm-dates > div > div.red {
    color: red;
}
.todaybar-container {
    position: relative;
}
.todaybar {
    position: absolute;
    height: 100%;
    border-left: 1px solid red;
    left: 50%;
    height: 100vh;
}

.c-room-heading {
    display: block;
    padding: 20px 10px 10px;
    font-weight: bold;
}
.c-room {
    display: flex;
    font-size: 0.8em;
    border-bottom: 1px solid #eee;
    height: 40px;
}

.c-room-month {
    width: 100%;
    display: flex;
}
.c-room .c-room-name {
    padding: 10px;
}
.c-room .c-m-dates.c-wm-dates > div {
    height: 40px;
    background: none;
}
.c-room .c-m-dates.c-wm-dates > div.ui-selecting {
    background: #fafafa;
    border-bottom: 1px solid #ddd;
}
.c-room .c-m-dates.c-wm-dates > div.ui-selected {
    background: #def;
    border-right-color: #fff;
    border-bottom: 1px solid #fff;
}
.c-m-dates.c-wm-dates > div.booking-bubble {
    position: absolute;
    left: 0;
    top: 5px;

    width: 3.225806451612903%;
    background: #222;
    border-radius: 7px;
    height: 30px;
    margin-left: 1.612903225806452%;
    display: flex;
    align-items: center;
    padding: 5px;
    color: #fff;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    border: 2px solid #fff;
    white-space: nowrap;
}
.c-m-dates.c-wm-dates > div.booking-bubble strong {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
.c-room-heading-container {
    border-bottom: 1px solid #eee;
}
.c-room-heading {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}
.c-room-heading::before {
    content: " ";
    width: 7px;
    height: 7px;
    border: 2px solid #000;
    border-width: 2px 2px 0 0;
    transform: rotate(-45deg);
    display: block;
    margin-right: 10px;
    margin-top: 5px;
}
.c-room-group.off {
    background: #fafafa;
}
.c-room-group.off .c-room {
    display: none;
}
.c-room-group.off .c-room-heading::before {
    transform: rotate(135deg);
    margin-top: -5px;
}
.custom-menu {
    display: none;
    z-index: 1000;
    position: absolute;
    overflow: hidden;
    border: 1px solid #ccc;
    white-space: nowrap;
    font-family: sans-serif;
    background: #fff;
    color: #333;
    border-radius: 5px;
    margin: 0;
    padding: 0;
    font-size: 0.85em;
}

.custom-menu li {
    padding: 8px 12px;
    cursor: pointer;
    list-style: none;
}

.custom-menu li:hover {
    background-color: #fafafa;
}
.c-room .c-m-dates.c-wm-dates > div.room-mainenance {
    background: #fbf4dd url(/images/icons/maintenance.svg) no-repeat center center;
    background-size: 16px;
}
.c-room .c-m-dates.c-wm-dates > div.holdbooking {
    background: #fff url(/images/icons/d-strip.png) 0 0;
}
.calendar-dates-heading .calendar-month {
    border-left: 0;
}
.calendar-dates-heading .c-m-dates.c-wm-dates {
    border-left: 1px solid #eee;
}
.c-m-dates.c-wm-dates > div.booking-bubble.unconfirmedBooking {
    border: 1px dashed #000;
    color: #000;
}
.c-m-dates.c-wm-dates > div.booking-bubble.unconfirmedBooking.group-selection {
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
    border: 2px solid var(--main-button-color);
    color: var(--main-button-color);
}
.group-actions {
    padding-right: 10px;
    margin-left: auto;
    display: none;
}
@media screen and (max-width: 1000px) {
    .croom-text {
        display: none;
    }
    .c-room-name {
        width: 50px;
    }
    .calendar-month {
        width: calc(100% - 50px);
    }
}
