.promo-header {
    display: flex;
    justify-content: space-between;
    font-size: 0.85em;
    align-items: center;
    padding: 20px 30px 20px;
}
.promo-filter label {
    display: flex;
    align-items: center;
    margin-right: 10px;
}
.promo-filter {
    display: flex;
}
.promo-type {
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.promo-type select,
.promo-type input {
    margin-left: 10px;
}
.promolist {
    padding: 0 30px 30px 20px;
}
.promolist table {
    width: 100%;
    border-collapse: collapse;
    border-top: 1px solid #ddd;
}
.promolist table th,
.promolist table td {
    padding: 10px;
    font-size: 0.95em;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.bubble {
    display: inline;
    padding: 0.2em 0.6em 0.3em;
    font-size: 11px;
    font-weight: bold;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;
}
.active-promo {
    background: #098618;
}
.promolist table th a {
    color: var(--main-button-color);
    text-decoration: underline;
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding-right: 20px;
}
.promolist table th a:hover {
    text-decoration: none;
}
.promolist table th a::after,
.promolist table th a::before {
    width: 0;
    height: 0;
    content: " ";
    display: block;
    position: absolute;
    right: 0;
}
.promolist table th a::before {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid #bbb;
    top: 0;
}
.promolist table th a::after {
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #bbb;
    bottom: 0;
}
.action-common {
    margin-right: 30px;
    display: none;
}
.action-common.on {
    display: flex;
}
.search-promo {
    padding-left: 30px;
}
.showmore {
    padding: 30px;
    text-align: center;
}
.showmore a {
    display: inline-block;
    font-size: 0.8em;
    background: #fafafa;
    border: 1px solid #ddd;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
}
.excluded-p-group {
    display: flex;
    align-items: flex-end;
}
.excluded-p-group ol {
    margin: 0;
    padding: 0 0 0 15px;
    width: 100%;
}
.addexcudedDates {
    width: 42px;
    padding-left: 10px;
}

.addexcudedDates .bu {
    min-width: 30px;
    height: 30px;
}
.promocode-details {
    display: flex;
    justify-content: space-between;
}
.promocode-details > * {
    width: calc(25% - 10px);
    flex-grow: 0;
}
.excluded-p-group input {
    padding: 2px;
}
.select-prommo-exc-date {
    display: none;
}
.package-action {
    display: flex;
}
.package-action .bu {
    margin-left: 10px;
}
.cutomPromocodeName {
    display: none;
}
.create-promocode {
    margin: auto;
    padding: 20px;
}
.promo-booking-dates {
    display: flex;
    justify-content: space-between;
}
.promo-booking-dates > * {
    width: calc(33.333% - 10px);
}
fieldset em {
    font-size: 0.8em;
    display: block;
    padding: 10px 10px 0;
    font-style: normal;
    color: #666;
}
.promocode-includes {
    display: flex;
    justify-content: space-between;
}
.promocode-includes > .cash-discount {
    width: calc(33% - 10px);
    flex-grow: 0;
}
.promocode-includes > .cash-discount {
    width: calc(25% - 10px);
    flex-grow: 0;
}
.promocode-includes > .included-addons {
    width: calc(75% - 10px);
}
.FreeAddon {
    font-size: 2em;
}
.addonTable {
    margin-bottom: 10px;
}
.Numberofpromocodeuse {
    display: none;
}
.select-promocode-group {
    display: none;
    padding-top: 20px;
}
.add-package-group {
    color: var(--main-button-color);
    font-size: 0.8em;
    text-decoration: underline;
    cursor: pointer;
}
.effective-promocode {
    display: flex;
    justify-content: space-between;
}
.effective-promocode > * {
    width: calc(33% - 10px);
    flex-grow: 0;
}
.gutter {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px 0;
}
